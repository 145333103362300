import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { postData } from '../services/api';
import CourseSideBar from '../components/pagesections/CourseSideBar';
import CourseQuizSection from '../components/pagesections/CourseQuiz/CourseQuizSection';
import {
  getCourseDetailsRequested,
  getCourseDetails,
  getCourseDetailsFailed,
  emptyCourseDetails,
  getCourseQuizRequested,
  getCourseQuiz,
  getCourseQuizFailed,
  emptyCourseQuiz
} from '../store/courseSlice';
// import { formatDateTZ } from '../helpers/date';
import toastr from 'toastr';
// import TermsLoader from '../components/Loader/TermsLoader';
import CourseDetailsQuizLoader from '../components/Loader/CourseDetailsQuizLoader';
// import CourseQuizLoader from '../components/Loader/CourseQuizLoader';
import { getPrevVideo } from '../components/shared/course';
import MetaTitle from '../components/documenuts/MetaTitle';

const CourseDetailsQuiz = () => {
  const dispatch = useDispatch()
  const { id, curId } = useParams()

  const [lastAns, setLastAns] = useState(false)
  const user = useSelector(state => state.auth.user)
  const authCode = useSelector(state => state.auth.user?.role_details?.code)
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector(state => state.course.details.data)
  const loading = useSelector(state => state.course.details.loading)
  const quizzes = useSelector(state => state.course.quiz.data)
  const quizzesLoading = useSelector(state => state.course.quiz.loading)
  const navigate = useNavigate()
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [seconds, setSeconds] = useState(null)

  const curriculums = courseDetails.curriculums
  const curriculum = curriculums?.find(curriculum => (
    curriculum.videos.map(
      item => item._id
    ).includes(curId)
  ))
  const curVidIndex = curriculum?.videos.findIndex(video => video._id === curId)
  const curCurIndex = curriculums?.findIndex(curriculum => (
    curriculum.videos.map(
      item => item._id
    ).includes(curId)
  ))
  const nextVidId = curriculum ? curriculum.videos[curVidIndex + 1]?._id : null
  const nextCurId = curriculums ? curriculums[curCurIndex + 1]?._id : null

  const getPassDetails = () => {
    if (courseDetails?._id) {
      let video

      courseDetails.curriculums.forEach(curriculum => {
        const curriculumVideo = curriculum.videos.find(video => video._id === curId)
        if (curriculumVideo) {
          video = curriculumVideo
        }
      })

      if (video && video.passed) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    const loadCourseDetails = async () => {
      if (!id) return

      dispatch(getCourseDetailsRequested())
      const res = await postData('course/details', { id })
      if (res.status === 200) {
        if (res.data.coming_soon) {
          navigate('/')
        } else {
          dispatch(getCourseDetails(res.data))
        }
      } else {
        dispatch(getCourseDetailsFailed())
        navigate('/')
      }
    }

    loadCourseDetails()

    return () => {
      dispatch(emptyCourseDetails())
    }
  }, [id, dispatch])

  useEffect(() => {
    const loadCourseQuiz = async () => {
      if (!curId) return

      dispatch(getCourseQuizRequested())
      const res = await postData('quiz/list', {
        curriculum_list_id: curId,
        course_id: id
      })
      if (res.status === 200) {
        dispatch(getCourseQuiz(res.data))
      } else {
        dispatch(getCourseQuizFailed())
      }
    }

    loadCourseQuiz()

    return () => {
      dispatch(emptyCourseQuiz())
    }
  }, [curId, id, dispatch])

  useEffect(() => {
    let interval

    if (courseDetails?._id && quizzes.length) {
      let video

      courseDetails.curriculums.forEach(curriculum => {
        const curriculumVideo = curriculum.videos.find(video => video._id === curId)
        if (curriculumVideo) {
          video = curriculumVideo
        }
      })

      if (video && !video.passed) {
        const startQuiz = async () => {
          await postData('quiz/attempt', {
            course_id: courseDetails?._id,
            curriculum_id: id,
            curriculum_list_id: curId,
          })
        }

        startQuiz()

        let startTime = video.start_time ? new Date(video.start_time) : new Date()

        if (video.start_time) {
          const diffMs = (new Date().getTime() - startTime.getTime());

          const diffInMins = Math.round(diffMs / 60000);

          if (diffInMins >= video.quiz_time) {
            startTime = new Date()
          }
        }

        interval = setInterval(() => {
          setSeconds(
            ((video.quiz_time * 60) -
              (Math.floor(((new Date().getTime() - startTime.getTime()) / 1000))))
          )
        }, 1000)
      }
    }

    return () => {
      if (interval) clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetails?._id, curId, id, quizzes.length])

  const nextQuestion = (event) => {
    setSelectedQuestion(quizzes[event + 1]._id);
  };

  useEffect(() => {
    if (quizzes.length && !selectedQuestion) {
      setSelectedQuestion(quizzes[0]._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizzes.length, selectedQuestion])

  const getVideoItem = () => {
    let videoItem = {}

    courseDetails?.curriculums?.forEach(curriculum => {
      curriculum?.videos?.forEach(video => {
        if (video._id === curId) {
          videoItem = video
        }
      })
    })

    return videoItem
  }

  const quizable = courseDetails?._id ? getPrevVideo(getVideoItem(), courseDetails).quizable : false

  const onSubmit = async () => {
    setLastAns(true)
    const res = await postData('quiz/attempt', {
      course_id: courseDetails?._id,
      curriculum_id: id,
      curriculum_list_id: curId,
      results: quizzes.map(quiz => ({
        question_id: quiz._id,
        answer_id: quiz.answer.find(item => item.checked)?._id || null
      }))
    })

    if (res.status === 200) {
      setLastAns(false)
      if (!res.passed) {
        toastr.error(
          'Eksam ei saanud sooritatud, proovige uuesti.', '', { timeOut: 30000 }
        )
        navigate(`/course-video-player/${id}?videoId=${curId}`)
      } else {
        toastr.success('Eksam sooritatud edukalt!')
        if (nextVidId && curVidIndex >= 0) {
          navigate(`/course-video-player/${id}?videoId=${nextVidId}`)
        } else if (nextCurId) {
          const nextCurVideoId = curriculums[curCurIndex + 1]?.videos[0]?._id

          if (nextCurVideoId) {
            navigate(`/course-video-player/${id}?videoId=${nextCurVideoId}`)
          }
        } else {
          navigate(`/my-course`)
        }
      }
    } else {
      setLastAns(false)
      toastr.error(res.message)
    }
  }

  if (user?._id && (authCode !== 'user' && !user?.is_company_owner)) {
    navigate('/')
    return <></>
  }

  return (
    <>
      <MetaTitle 
        title={
          courseDetails?.title ? 
          `Radisol | ${courseDetails?.title[activeLang]}` : 'Radisol | Course'
        } 
      />
      <section className='pt-3 pt-xl-5 bg-light'>
        <div className='container' data-sticky-container>
          <div className='row g-4'>
            <div className='col-xl-8'>
              <div className='row g-4'>
                {
                  !loading
                    ?
                    <div className='col-12'>
                      <h2>{courseDetails.title ? courseDetails.title[activeLang] : ''}</h2>
                      <p>
                        {courseDetails.sub_title ? courseDetails.sub_title[activeLang] : ''}
                      </p>

                      <ul className='list-inline mb-0'>
                        {
                          courseDetails?.instructor?.name &&
                          <li className='list-inline-item h6 mb-0'>
                            <i className='fas fa-user text-info me-2'></i>{
                              courseDetails?.instructor?.name || ''
                            }
                          </li>
                        }

                        {/* {
                    courseDetails.updated_at &&
                    <li className='list-inline-item h6 ms-3 mb-1 mb-sm-0'>
                      <i className='bi bi-patch-exclamation-fill text-danger me-2'></i>
                      {
                        `Last updated ${formatDateTZ(new Date(courseDetails.updated_at), 'MM/YYYY')
                        }`
                      }
                    </li>
                  } */}
                      </ul>
                    </div>
                    :
                    <CourseDetailsQuizLoader />
                }
                {
                  (courseDetails?._id && !quizable) ?
                  (<h2>Sorry, this section is not available.</h2>) :
                  (courseDetails?._id && !getPassDetails()) ?
                    <CourseQuizSection
                      questions={quizzes}
                      nextQuestion={nextQuestion}
                      selectedQuestion={selectedQuestion}
                      setSelectedQuestion={setSelectedQuestion}
                      onSubmit={onSubmit}
                      seconds={seconds}
                      lastAns={lastAns}
                      loading={quizzesLoading} 
                    /> :
                    courseDetails?._id && <h2>Selle loengu eksam on juba sooritatud. Palun valige järgmine moodul menüüst.</h2>
                }
              </div>
            </div>

            <div className='col-xl-4'>
              <CourseSideBar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseDetailsQuiz;
